import logo from './logo.png';
import boats from './boats.jpg';
import fish from './fish.jpg';
import plate from './plate.jpg';
import owners from './owners.jpg';
import './App.scss';

function App() {
    let content =  <div className="App">
        <div className="header">
            <div className="header-1">Responsibly harvested from the</div>
            <div className="header-2">Copper River Delta and Prince William Sound</div>
        </div>
        <div className="logo-slot"><img src={logo} alt={'logo'}/></div>
        <a className="contact-top" href={'mailto:info@peakseafoods.com'} target="_blank">Contact Us</a>
        <div className="image-slot"><img src={boats} alt={'boats'}/></div>

        <div className={'peak-holder'}>
            <div className="text-group">
                <div className="text-slot heading">Peak Seafoods</div>
                <div className="text-slot">Small fisherman owned sea to table company</div>
                <div className="text-slot">providing the highest quality salmon and seafood</div>
                <div className="text-slot">from the glacier fed waters of the Copper River</div>
                <div className="text-slot">Delta and Prince William Sound.</div>
            </div>
            <div className="text-group">
                <div className="text-slot">We are committed to providing a delicious clean</div>
                <div className="text-slot"> product to our communities using sustainable</div>
                <div className="text-slot"> fishing and respectful harvesting practices.</div>
            </div>
        </div>
        <div className="image-slot"><img src={fish} alt={'fish'}/></div>

        <div className={'anchor-holder'}>
            <div className="text-group">
                <div className="text-slot heading">Our Harvest</div>
                <div className="text-slot">We begin our season in May, fishing the first</div>
                <div className="text-slot">salmon run of the year, on the Copper River.</div>
                <div className="text-slot">We continue fishing throughout the summer in the</div>
                <div className="text-slot">pristine waters of Prince William Sound and then</div>
                <div className="text-slot">return to the Copper River Delta in the fall.</div>
            </div>
            <div className="text-group">
                <div className="text-slot">We harvest and sell all three premiere wild</div>
                <div className="text-slot">salmon species: King, Sockeye and Coho. All</div>
                <div className="text-slot">three are rich in heart-healthy omega-3</div>
                <div className="text-slot">fatty acids and full of wild flavor.</div>
            </div>
        </div>

        <div className="image-slot"><img src={plate} alt={'plate'}/></div>

        <div className={'fish-holder'}>
            <div className="text-group">
                <div className="text-slot heading">Our Fish Quality</div>
                <div className="text-slot">Stands above the rest. We take every step to ensure</div>
                <div className="text-slot">you receive the highest  quality product.</div>
            </div>
            <div className="text-group">
                <div className="text-slot">We hand select the best fish in our catch.</div>
                <div className="text-slot">We immediately bleed and slush ice the fish.</div>
                <div className="text-slot">We deliver frequently to our tender.</div>
                <div className="text-slot">We use small batch artisanal processing.</div>
            </div>
            <div className="text-group">
                <div className="text-slot">Your fish is traceable every step of the</div>
                <div className="text-slot">process from our boat to your table!</div>
            </div>
        </div>

        <div className="image-slot"><img src={owners} alt={'owners'}/></div>
        <div className="text-group">
            <div className="text-slot heading">Travis Magnusson</div>
            <div className="text-slot">Our Captain has been Salmon fishing in Alaska for</div>
            <div className="text-slot">over 15yrs. He started his fishing career in Bristol</div>
            <div className="text-slot">Bay as a deck hand, he then transitioned to fishing</div>
            <div className="text-slot">on Seiners in Prince William Sound, and 5 years ago</div>
            <div className="text-slot">he moved to owning and operating his own boat.</div>
        </div>
        <div className="text-group">
            <div className="text-slot heading">Cassie McCraw</div>
            <div className="text-slot">Our fearless deckhand and direct marketing leader</div>
            <div className="text-slot">has been fishing in Prince William Sound for 3 years.</div>
            <div className="text-slot">She prioritizes eating as close to the source as</div>
            <div className="text-slot">possible, eating wild sourced foods and using food</div>
            <div className="text-slot">as medicine. When she's not fishing you can find her</div>
            <div className="text-slot">at her Eastern Medicine practice in the Matsu Valley.</div>
        </div>
        <div className="end-section">
            <div className={'contact-bottom'}>Interested in buying clean wild protein</div>
            <div className={'contact-bottom'}>direct from the boat? Contact us at:</div>
            <div className={'contact-bottom'}><a href={'mailto:info@peakseafoods.com'} target="_blank">info@peakseafoods.com</a></div>
            <div className={'we-have-group'}>
                <div className={'boxes'}>We have 20 and 40lb boxes available</div>
                <div className={'boxes'}>during the following parts of the season:</div>
                <ul>
                    <li>Copper River King - May/June</li>
                    <li>Copper River Sockeye - May/June</li>
                    <li>Prince William Sockeye - June/July</li>
                    <li>Copper River Coho - Aug/Sept</li>
                </ul>
            </div>
        </div>
    </div>;

    return content
}

export default App;
